

.containerProyects .title{
    text-align: center;
    font-size: var(--txt-mobil-xl);
}

.wrapProyects{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.wrapProyects a{
    width: 100%;
    background: var(--main-bg-color);
    color: var(--primary-txt-color);
    padding-top:10px;
    padding-bottom:10px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.containerProyect p{
    text-align: center;
}


@media only screen and (min-width: 800px){


    .wrapProyects a{
        width: 50%;
    }

    .containerProyects{
        width: 100%;
    }

}

@media only screen and (min-width: 1200px){
    .wrapProyects a{
        width: 33%;
    }
}