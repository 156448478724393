:root{

  /**Colors**/
  --main-bg-color: #0F2027;


  --primary-txt-color: rgba(227, 230, 232,1);
  --secondary-txt-color: rgba(53, 59, 64, 1);

  /**Sizes**/
  /**Mobil**/
  --txt-mobil-xxl: 1.75rem;
  --txt-mobil-xl: 1.5rem;
  --txt-mobil-m: 1.2rem;
  --txt-mobil-l: 1rem;
  --txt-mobil-s: 0.8rem;
}

html{

  font-family: 'Arvo', serif;
}



*, *::before, *::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}
ul, li, h1, h2, p, button {
  margin: 0;
  padding: 0%;
}
ul{
  list-style: none;
}
body{
  width: 100%;
  height: 100vh;
  background: #8e9eab;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #eef2f3, #8e9eab);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #eef2f3, #8e9eab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-attachment:fixed;
}


#app{
  width: 100%;
}