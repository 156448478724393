.footer{
    width: 100%;
    height: 70px;
}

.containerRRSS{
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
}

footer .containerRRSS a svg{
    color: var(--main-bg-color);
} 

.footer p{
    padding-top: 10px;
    text-align: center;
}



