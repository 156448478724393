.containerNav{
  width: 100%;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  height: 50px ;
  padding-left: 10px;
  padding-right: 10px;
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.containerLogo{
  width: 200px;
  position: relative;
}

.containerLogo > * {
  position: absolute;
  top: -53px;
  width: 100%;
}

.containerHamb{
  color: rgba(237, 237, 237, 1);
  z-index: 4;
}

.containerlinkNavigator{
  display: none;
}


@media only screen and (min-width: 800px) {

  .containerHamb{
    display: none;
  }
  .containerlinkNavigator{
    display: flex;
    font-size: var(--txt-mobil-m); 
  }
  .containerlinkNavigator a{
    color: rgba(237, 237, 237, 1);
    text-decoration: none;
    padding: 6px;
  }
}
