.containerContact{
    width: 100%;
    height: 50%;
    display: grid;
    place-items: center;
}

.containerContact .title{
    margin-top: 20px;
}

.containerEmailLogo{
    width: 80%;
    display: grid;
    place-items: center;
 
}


.email-image{
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background-color:var(--main-bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.email-image img {
    width: 80px;
  }

  .containterAddress address{
      text-align: center;
  }

  @media only screen and (min-width: 800px) {
    .containerContact{
        display: grid;
        grid-template-rows: auto 1fr auto;
        justify-items: center;
    }

    .containerEmailLogo{
        width: 50%;
    }
  }