.containerProyectPage{
    width: 100%;
}

.containerProyectPage h1{
    text-align: center;
}

.containerProyectPage .containerIframeProyectPage{
    width: 80%;
    margin: 0 auto;

}

.containerProyectPage .containerIframeProyectPage iframe{
    width: 100%;
    height: 300px;
}

.containerProyectPage .wrapdescriptiontext{
    padding: 20px;
    margin-top: 30px;
    background: var(--main-bg-color);
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 14px 19px 25px -7px rgba(0,0,0,0.27);
    box-shadow: 0px 10px 13px -7px #000000, 14px 19px 25px -7px rgba(0,0,0,0.27);
}
.containerProyectPage .wrapdescriptiontext h3{
    color: var( --primary-txt-color);
    font-size: var( --txt-mobil-m);
}
.containerProyectPage .wrapdescriptiontext p {
    margin-top: 5px;
    color: var( --primary-txt-color);
    font-size: var( --txt-mobil-l);
    margin-bottom: 30px;
}

.containerProyectPage .wrapdescriptiontext li{
    color: var( --primary-txt-color);
    font-size: var( --txt-mobil-l);
    padding-left: 30px;

}

.containerButtonProyectPage{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 30px;
}
.containerButtonProyectPage a{
    width: 100%;
    margin-top: 10px;
    padding: 5px;
    color: var(--primary-txt-color);
    text-decoration: none;
    background-color: #367893;
    border-radius: 25px;
    text-align: center;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 14px 19px 25px -7px rgba(0,0,0,0.27);
    box-shadow: 0px 10px 13px -7px #000000, 14px 19px 25px -7px rgba(0,0,0,0.27);
}


@media only screen and (min-width: 800px){
  
    .containerProyectPage .containerIframeProyectPage{
        width: 60%;
        margin: 0 auto;
    }

    .containerProyectPage .wrapdescriptiontext{
        margin: 30px auto  10px auto;
        width: 80%;
       
    }

}

@media only screen and (min-width: 1200px){
    .containerProyectPage .wrapdescriptiontext{
        padding: 70px;
    }
    .containerProyectPage .wrapdescriptiontext p{
        text-align: justify;
    }
    .containerButtonProyectPage a{
        width: 30%;
        margin: 10px;
    }
}