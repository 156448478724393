.layout{
    width: 100%;
    height: 100vh;
    min-width: 300px;
    position: relative;
}

.layoutPageContent{
    min-height: 560px ;
}

.containerMobilMenu{
    height: 100%;
    width: 70%;
    position: fixed;
    top: -1px;
    right: 0;
    z-index: 3;
    background: #0F2027;  
    background: -webkit-linear-gradient(to right, rgba(44, 83, 100, 0.3), #203a434d, rgba(15, 32, 39, 0.3)); 
    background: linear-gradient(to right, rgba(44, 83, 100, 0.85), rgba(31, 57, 65, 0.85), rgba(15, 31, 38, 0.85));
}

.containerMobilMenu ul {
    height: 100vh;
}
.containerMobilMenu li{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap
    
}

.containerMobilMenu ul li a{
    padding-top: 10%;
    padding-bottom: 10%;
    display: flex;
    justify-content: center;
    color: white;
    text-decoration: none
}
.containerMobilMenu ul li a:nth-child(1){
    margin-top: 50px;
}



@media only screen and (min-width: 800px){
    .containerHomePage{
        margin: 0 auto;
        width: 80%;
    }

   
}


@media only screen and (min-width: 1200px){
 .layoutPageContent{
      min-height: 650px;
    }
}