
.contentHome{
    width: 100%;
 }

.containerTyperwiter{
    font-size: var(--txt-mobil-m);
    padding-left: 10px;
    display: flex;
    width: 100%;
}

.containerTyperwiter span{
    font-size: 140%;
}

.containerPhoto{
    margin: 0 auto;
    width: 70%;
    max-height: 375px;
}

.containerPhoto img{
    width: 100%;
    height: 100%;
}

.positionTextDescription{
    position: relative;

}

.backgroudText{
    font-size: var(--txt-mobil-l);
    background: #0F2027;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    text-align: justify;
    color: var(--primary-txt-color);
    padding: 25px;
    z-index: 2;
    position: absolute;
    top: -15px;
    border-radius: 0 25px 0 0 ;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 14px 19px 25px -7px rgba(0,0,0,0.27);
    box-shadow: 0px 10px 13px -7px #000000, 14px 19px 25px -7px rgba(0,0,0,0.27);
    width: 80%;
}



@media only screen and (min-width: 800px) {


    .containerTyperwiter {
        width: 60%;
        font-size: var( --txt-mobil-m);
        margin: 0 auto;
    }


    .containerPhoto{
        width: 30%;
        max-width: 500px;
    }

    .positionTextDescription{
        margin: 0 auto;  
     }


    .backgroudText{
        position: relative;
        margin: 0 auto;
        width: 60%;
        top: -5vh;
        font-size: var( --txt-mobil-l);
    }
}
    
@media only screen and (min-width: 1200px){
    .containerTyperwiter {
        font-size: var( --txt-mobil-xxl);
        width: 58%;
    }

    .contentHome{
        min-height: 550px;
    }
    

    .backgroudText{
        padding: 40px;
        width: 40%;
        top: -2vh;
    }

}
