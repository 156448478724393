.containerProyect{
    margin: 0 auto;
}

.containerProyect h1{
    text-align: center;
    text-transform: uppercase;
    font-size: var( --txt-mobil-m);

}

.wraperProyectIframe{
    margin: 0 auto;
    width: 80%;
}

.wraperProyectIframe iframe{
    width: 100%;
}

.containerProyect p{
    font-size: var( --txt-mobil-l);
}


@media only screen and (min-width: 800px){

    .containerProyect{
        margin: 10px;
    }

    .wraperProyectIframe{
        width: 100%;
    }

}